import { Transforms } from 'slate';
import { jsx } from 'slate-hyperscript';
import { ReactEditor } from 'slate-react';
import { FormattedText } from 'types/slate';

const ELEMENT_TAGS: {
  [key: string]: (el: Node) => Object;
} = {
  A: (el) => ({
    type: 'link',
    url: (el as HTMLAnchorElement).getAttribute('href'),
  }),
  LI: () => ({ type: 'list-item' }),
  UL: () => ({ type: 'bulleted-list' }),
};

const TEXT_TAGS: {
  [key: string]: () => Pick<FormattedText, 'italic' | 'bold'>;
} = {
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  STRONG: () => ({ bold: true }),
  B: () => ({ bold: true }),
};

/**
 * This plugin supports pasting HTML into Slate.  It is taken in large part from
 * the SlateJS docs: https://github.com/ianstormtaylor/slate/blob/9de2e2c3162f88260e7255ed824dcd7b9d48110c/site/examples/paste-html.tsx
 *
 * Currently it only supports pasting in various bold tags (<b>, <strong>) and italics (<i>, <em>) and line breaks (<br />)
 * But it could easily be exteneded to support much more by augmenting the ELEMENT_TAGS and TEXT_TAGS above as done
 * in the example code.  The deserializeHTMLHTML function will already support additional tags.
 */
export const withHtml = (editor: ReactEditor) => {
  const { insertData } = editor;

  // The only way these plugins work is by mutating the
  // editor in place- you cannot do a spread.  I tried.
  /* eslint-disable no-param-reassign */
  editor.insertData = (data) => {
    const html = data.getData('text/html');

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserializeHTML(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };
  /* eslint-enable no-param-reassign */

  return editor;
};

// Recursive function that converts HTML into Slate-formatted JSON objects
// Should support most common HTML tags as written
const deserializeHTML = (el: Node): any => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }
  if (el.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    // Node.childNodes is an iterator, not an array
    // eslint-disable-next-line prefer-destructuring
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserializeHTML).flat();

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName]();
    return children.map((child) => jsx('text', attrs, child));
  }

  return children;
};
