import {
  FC,
  useCallback,
  useState,
  ChangeEventHandler,
  FocusEventHandler,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import { styled, css, Box } from '@mui/material';
import { Search } from '@mui/icons-material';

import {
  AttributeOptionWithKey,
  KeywordAttribute,
  OnAttrSelect,
  OnAttrRemove,
} from '../types';

import SelectedAttributes from './selected-filter-chip';

type Props = {
  searchTerm: string;
  placeholder?: string;
  keywordAttribute: KeywordAttribute | undefined;
  selectedAttributes: AttributeOptionWithKey[];
  onAttrSelect: OnAttrSelect;
  onAttrRemove: OnAttrRemove;
  onChange: ChangeEventHandler;
  onFocus?: FocusEventHandler;
};
type StyledProps = {
  $inputHasFocus: boolean;
};

/* styled components */
const Wrapper = styled('div')<StyledProps>`
  width: 100%;
  position: relative;
  vertical-align: top;
  margin: 0;
  padding: 0;
  color: var(--color-neutral-seven);
  background-color: #fff;
  z-index: 6; // matches z-index of the results

  border: solid 1px;
  border-color: ${({ theme }) => theme.palette.neutralSix.main};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows[1]};

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4375rem;
  letter-spacing: 0.00938em;

  :hover {
    border-color: ${({ theme }) => theme.palette.neutralEight.main};
  }

  /* gives the blue border when the input has focused */
  ${({ $inputHasFocus, theme }) =>
    $inputHasFocus &&
    css`
      border-color: ${theme.palette.primary.main};

      :hover {
        border-color: ${theme.palette.primary.main};
      }
    `}
`;

const FadedSearchIcon = styled(Search)`
  color: rgba(0, 0, 0, 0.54);
`;

const StyledInput = styled('input')`
  padding-left: 0.75rem;
  margin: 0;
  flex: 1 1 350px;
  border: none;
  background: none;
  color: inherit;
  box-sizing: content-box;
  height: 1.4375rem;
  outline: none;

  font: inherit;
  letter-spacing: inherit;

  ::placeholder {
    color: var(--color-neutral-five);
    opacity: 0.8;
  }
`;

const SearchInput: FC<Props> = ({
  searchTerm,
  placeholder,
  selectedAttributes,
  keywordAttribute,
  onAttrSelect,
  onAttrRemove,
  onChange,
  onFocus,
}) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const defaultPlaceholder =
    'Add attributes and keywords to find content for reuse';

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setInputHasFocus(true);
      e.target.select();
      if (onFocus) onFocus(e);
    },
    [onFocus]
  );
  const handleBlur = useCallback(() => {
    setInputHasFocus(false);
  }, []);

  const handleKeyboardCommands = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      // If just "Enter" is pressed, the Content Title (keyword) will be added as an attribute
      switch (e.key) {
        case 'Enter':
          if (keywordAttribute) {
            onAttrSelect(keywordAttribute);
            // @ts-ignore - says select isn't an option; but it works
            e.target?.select();
          }
          break;

        case 'Backspace':
          // checking both start and end prevents the case when a user highlights the
          // entire input text, and presses "backspace". If just the start was checked
          // then it could also remove an attribute chip.
          // @ts-ignore - says selectionStart isn't an option; but it works
          if (e.target.selectionStart === 0 && e.target.selectionEnd === 0) {
            const [lastAttribute] = selectedAttributes.slice(-1);
            lastAttribute && onAttrRemove(lastAttribute);
          }
          break;
      }
    },
    [keywordAttribute, selectedAttributes, onAttrSelect, onAttrRemove]
  );

  return (
    <Wrapper
      className="attribute-wrapper"
      data-testid="attribute-search-input-wrapper"
      $inputHasFocus={inputHasFocus}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        width="100%"
        gap="0.25rem"
        padding="0.35rem 0 0.35rem 1rem"
      >
        <FadedSearchIcon />
        <SelectedAttributes
          selectedAttributes={selectedAttributes}
          onAttrRemove={onAttrRemove}
        />
        <StyledInput
          id="attribute-search-input"
          data-testid="attribute-search-input"
          type="search"
          value={searchTerm}
          placeholder={placeholder || defaultPlaceholder}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyboardCommands}
          autoComplete="off"
          autoCorrect="off"
        />
      </Box>
    </Wrapper>
  );
};

export default SearchInput;
