import {
  useReducer,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { reducer, initialState } from './topic-reducer';

export const TopicContext = createContext();
export const TopicDispatchContext = createContext();
export const UndoRedoContext = createContext();
export const RequestEditingStatusContext = createContext();
export const AutoSaveContext = createContext();
export const EditingStatusContext = createContext();

const TopicProvider = (props) => {
  const [topic, dispatchTopic] = useReducer(reducer, initialState);
  const [canUndoRedo, setCanUndoRedo] = useState({
    canUndo: false,
    canRedo: false,
  });
  const editingStatusRef = useRef({});
  const editingStatus = {
    isEditing: topic.isEditing,
    isEditingIndicators: topic.isEditingIndicators,
    curEditingStep: topic.curEditingStep,
  };

  useEffect(() => {
    if (topic.stepRefs.length > 0 && topic.scrollToStep >= 0) {
      // const timer = setTimeout(() => {
      // Scroll the step into view at top of window
      const y =
        topic.stepRefs[topic.scrollToStep].current.getBoundingClientRect().top +
        window.pageYOffset -
        (92 + 55);
      window.scrollTo({ top: y, behavior: 'smooth' });
      dispatchTopic({ type: 'CLEAR_SCROLL_TO_STEP' });
    }
  }, [topic.scrollToStep, topic.stepRefs]);

  // Can undo/redo if there is something in the past/future array
  // Also don't allow additional undo/redo if a scroll triggered by an undo/redo
  // is already in progress
  useEffect(() => {
    if (topic.past !== undefined && topic.future !== undefined) {
      setCanUndoRedo({
        canUndo: topic.past.length > 0 && !topic.isScrollInProgress,
        canRedo: topic.future.length > 0 && !topic.isScrollInProgress,
      });
    }
  }, [topic.past, topic.future, topic.isScrollInProgress]);

  // Whenever the topic editing status fields change copy them into the editing
  // status ref value so they can be read later through context
  useEffect(() => {
    editingStatusRef.current = {
      isEditing: topic.isEditing,
      isEditingIndicators: topic.isEditingIndicators,
      curEditingStep: topic.curEditingStep,
    };
  }, [topic.curEditingStep, topic.isEditing, topic.isEditingIndicators]);

  // Callback function to get the edting status fields.  We useCallback here because
  // we need the function to have referential integrity between renders.  We use a ref
  // to hold the values so the callback function does not get recreated when the topic
  // values change, which would trigger a context-initiated rerender in any component that consume it
  const getEditingStatus = useCallback(() => {
    const {
      isEditing,
      isEditingIndicators,
      curEditingStep,
    } = editingStatusRef.current;
    return {
      isEditing,
      isEditingIndicators,
      curEditingStep,
    };
  }, []);

  return (
    <TopicContext.Provider value={topic}>
      <TopicDispatchContext.Provider value={dispatchTopic}>
        <UndoRedoContext.Provider value={canUndoRedo}>
          <RequestEditingStatusContext.Provider value={getEditingStatus}>
            <EditingStatusContext.Provider value={editingStatus}>
              <AutoSaveContext.Provider value={topic.autoSaveInProgress}>
                {props.children}
              </AutoSaveContext.Provider>
            </EditingStatusContext.Provider>
          </RequestEditingStatusContext.Provider>
        </UndoRedoContext.Provider>
      </TopicDispatchContext.Provider>
    </TopicContext.Provider>
  );
};

const useTopicContext = () => useContext(TopicContext);
const useTopicDispatchContext = () => useContext(TopicDispatchContext);
const useUndoRedoContext = () => useContext(UndoRedoContext);
const useRequestEditingStatusContext = () =>
  useContext(RequestEditingStatusContext);
const useAutoSaveContext = () => useContext(AutoSaveContext);
const useEditingStatusContext = () => useContext(EditingStatusContext);

export default TopicProvider;
export {
  TopicProvider,
  useTopicContext,
  useTopicDispatchContext,
  useUndoRedoContext,
  useRequestEditingStatusContext,
  useAutoSaveContext,
  useEditingStatusContext,
};
