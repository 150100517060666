import { FunctionComponent, useState, useEffect } from 'react';
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  FormControl,
  InputLabel,
} from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import PropTypes from 'services/utils/prop-types';
import LoadingIndicator from 'components/loading-indicator';

type Props = {
  deviceId: number;
  onSelect: (shell?: DeviceShellModel) => void;
};

const SelectDeviceShell: FunctionComponent<Props> = ({
  deviceId,
  onSelect,
}) => {
  const api = useOzmoApiService();
  const { all: shells, isLoading, isFetching } = api.DeviceShell.getAll({
    deviceId,
  });
  const [selected, setSelected] = useState<DeviceShellModel>();

  const handleSelect = (event: SelectChangeEvent<DeviceShellModel>) => {
    const value = event.target.value as DeviceShellModel;
    setSelected(value);
    onSelect(value);
  };

  useEffect(() => {
    if (shells?.length > 0) {
      const defaultShell = shells.find((shell) => shell.default) ?? shells[0];
      setSelected(defaultShell);
      onSelect(defaultShell);
    }
  }, [onSelect, shells]);

  if (isLoading || isFetching) {
    return <LoadingIndicator message="Loading shells for device" />;
  }

  if (shells.length === 0) {
    return (
      <Typography display="flex" alignItems="center">
        {'No shells configured for device. '}
        <a
          style={{ display: 'contents' }}
          target="_blank"
          rel="noreferrer"
          href={`https://cms.ozmoapp.com/#/config/devices/${deviceId}/assets`}
        >{`Edit in CMS`}</a>
      </Typography>
    );
  }

  if (!selected) return null;

  return (
    <FormControl>
      <InputLabel id="select-label">{'Device Shell'}</InputLabel>
      <Select
        style={{ height: '100%' }}
        value={selected}
        label="Device Shell"
        labelId="select-label"
        onChange={handleSelect}
        data-testid="select-device-shell:select"
      >
        {shells.map((shell) => (
          <MenuItem key={shell.id} value={shell as any}>
            {`${shell.name}${shell.default ? ' (default)' : ''}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectDeviceShell.propTypes = {
  deviceId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectDeviceShell;
