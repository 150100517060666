import { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import { ReactComponent as UploadIcon } from 'icons/streamline-ux-cloud-upload-1.svg';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import DragAndDropArea from './drag-and-drop';

const StyledDragAndDropChildren = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 2.5rem;
`;

const StyledUploadLabel = styled.label`
  text-decoration: none;
  cursor: pointer;
  color: var(--color-primary-base);
  & > input[type='file'] {
    display: none;
  }
`;

type Props = {
  onDrop: (fileOrFiles: File | FileList) => void;
  onError: (error: string) => void;
  allowBulk: boolean;
  withFileSelector?: boolean;
  acceptedType?: string;
};

const DragAndDropAreaContainer: FCWithChildren<Props> = ({
  onDrop,
  children,
  onError,
  allowBulk = false,
  withFileSelector = false,
  acceptedType = 'image/*',
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const dragEventCounterRef = useRef(0);
  const dropzoneRef = useRef<HTMLDivElement | null>(null);

  const decrementDragEventCounter = () => (dragEventCounterRef.current -= 1);
  const incrementDragEventCounter = () => (dragEventCounterRef.current += 1);
  const toggleIsDraggingOver = () =>
    setIsDraggingOver((cur) =>
      dragEventCounterRef.current === 0 ? !cur : true
    );

  const handleDragIn = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
    incrementDragEventCounter();
    toggleIsDraggingOver();
  }, []);

  const handleDragOut = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
    decrementDragEventCounter();
    toggleIsDraggingOver();
  }, []);

  const handleDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(false);
      const { files } = event.dataTransfer;

      if (files.length === 1) {
        onDrop(files[0]);
      } else if (files.length > 1 && allowBulk) {
        onDrop(files);
      } else {
        onError('Please only upload one file at a time');
      }
      event.dataTransfer.clearData();
    },
    [onDrop, onError, allowBulk]
  );

  const handleDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    files && onDrop(files);
  };

  useEffect(() => {
    const dropzone = dropzoneRef.current;
    if (dropzone) {
      dropzone.addEventListener('dragenter', handleDragIn);
      dropzone.addEventListener('dragleave', handleDragOut);
      dropzone.addEventListener('dragover', handleDragOver);
      dropzone.addEventListener('drop', handleDrop);
      // Cleanup function runs on unmount
      return () => {
        dropzone.removeEventListener('dragenter', handleDragIn);
        dropzone.removeEventListener('dragleave', handleDragOut);
        dropzone.removeEventListener('dragover', handleDragOver);
        dropzone.removeEventListener('drop', handleDrop);
      };
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  return (
    <DragAndDropArea ref={dropzoneRef} isDraggingOver={isDraggingOver}>
      <StyledDragAndDropChildren>
        <UploadIcon />
        <Typography>{'Drag and drop'}</Typography>
        {withFileSelector && (
          <StyledUploadLabel>
            <input
              id="fileSelect"
              data-testid="imagebutton:update-image-button"
              accept={acceptedType}
              type="file"
              multiple={allowBulk}
              onChange={handleFileSelect}
            />
            {'or browse your computer'}
          </StyledUploadLabel>
        )}

        {children}
      </StyledDragAndDropChildren>
    </DragAndDropArea>
  );
};

DragAndDropAreaContainer.propTypes = {
  onDrop: PropTypes.func.isRequired,
  withFileSelector: PropTypes.bool,
};

export default DragAndDropAreaContainer;
