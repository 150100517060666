import { QueryClient } from '@tanstack/react-query';
import snakeToCamelCase from 'services/utils/convert-object-keys-snake-to-camel';
import { LS_OZMO_TOKEN } from 'scenes/google-auth';

import Collection from './collection';
import ContentEntry from './content-entry';
import ContentType from './content-type';
import Language from './language';
import Locale from './locale';
import LocalizedCollection from './localized-collection';
import LocalizedContentEntry from './localized-content-entry';
import MediaEntry from './media-entry';
import Space from './space';
import Topic from './topic';
import Device from './device';
import DeviceType from './device-type';
import Manufacturer from './manufacturer';
import OperatingSystem from './operating-system';
import OperatingSystemRelease from './operating-system-release';
import OperatingSystemVersion from './operating-system-version';
import DeviceShell from './device-shell';
import ClientLocale from './client-locale';
import Client from './client';

// Helper function to make API requests in a way that react-query expects
const getAuthToken = () => localStorage.getItem(LS_OZMO_TOKEN);

/**
 * Do an HTTP request against the Ozmo API
 *
 * @param {String} resource - The path to the resource (e.g.: "authoring/content_entries")
 * @param {Object} body - The HTTP body
 * @param {String} method - the HTTP method verb (e.g.: 'POST')
 * @param {Object} customHeaders - (Optional) Define any manual headers that aren't included in the base request header.
 * @returns A Promise that will resolve to a dictionary with the HTTP response (status, etc)
 *  and the data requested (converted to camelCase)
 */
const ozmoApiRequest = (
  resource: string,
  body: any,
  method: string,
  withResponse: boolean = false,
  stringifyBody: boolean = true,
  customHeaders?: any
) =>
  new Promise<any | { data: any; response: Response }>((resolve, reject) => {
    if (['GET', 'DELETE', 'PATCH', 'PUT', 'POST'].indexOf(method) < 0) {
      return reject('Invalid HTTP verb');
    }
    const token = getAuthToken();
    if (!token) {
      return reject({ code: 401, message: 'Not logged in' });
    }
    const trimmedResource = resource.replace(/^\/+/, '');
    // Allow the user to override and specify a full url for an API call
    const url = resource.includes('https://')
      ? resource
      : `${process.env.REACT_APP_OZMO_API_URL}/${trimmedResource}`;

    let headers: Record<string, string> = {
      Authorization: `Bearer ${token}`,
      'X-Api-Key': `${process.env.REACT_APP_OZMO_X_API_KEY}`, // env var "can" be undefined, which TS doesn't like; so interpolate it
    };

    if (stringifyBody) {
      headers['Content-type'] = 'application/json';
    }

    if (customHeaders) {
      headers = { ...headers, ...customHeaders };
    }

    fetch(url, {
      headers,
      method,
      body: stringifyBody ? JSON.stringify(body) : body,
    })
      .then(async (response) => {
        if (response.ok) {
          // If status code is 204 (No Content), there will be no body
          // So the respose.json() call could fail, but the actual ozmo api call succeeded
          // So instead just return the response as the data so the user has access to the status
          // code and headers and whatnot
          if (response.status === 204) {
            return resolve(response);
          }
          const json = await response.json();
          const camelCasedJson = snakeToCamelCase(json);
          if (withResponse) {
            return resolve({ data: camelCasedJson, response });
          }
          resolve(camelCasedJson);
        } else {
          // Treat anything but a 200-level response as an error
          // Try to parse out a json response from the server
          const json = await response.json();
          if (json.code === 401) {
            localStorage.removeItem('ozmo-authoring-ozmo-token');
          }
          const camelCasedJson = snakeToCamelCase(json);
          if (withResponse) {
            return reject({ data: camelCasedJson, response });
          }
          reject(camelCasedJson);
        }
      })
      .catch((error) => reject(error));
  });

// The queryClient for the app
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
});

export type OzmoApiContext = {
  queryClient: QueryClient;
  Collection: typeof Collection;
  ContentEntry: typeof ContentEntry;
  ContentType: typeof ContentType;
  DeviceShell: typeof DeviceShell;
  Language: typeof Language;
  Locale: typeof Locale;
  LocalizedCollection: typeof LocalizedCollection;
  LocalizedContentEntry: typeof LocalizedContentEntry;
  MediaEntry: typeof MediaEntry;
  Space: typeof Space;
  Topic: typeof Topic;
  ClientLocale: typeof ClientLocale;
  Client: typeof Client;
  Device: typeof Device;
  DeviceType: typeof DeviceType;
  Manufacturer: typeof Manufacturer;
  OperatingSystem: typeof OperatingSystem;
  OperatingSystemRelease: typeof OperatingSystemRelease;
  OperatingSystemVersion: typeof OperatingSystemVersion;
};

const ozmoApi: OzmoApiContext = {
  queryClient,
  // Implemented models.
  Collection,
  ContentEntry,
  ContentType,
  DeviceShell,
  Language,
  Locale,
  LocalizedCollection,
  LocalizedContentEntry,
  MediaEntry,
  Space,
  Topic,
  ClientLocale,
  Client,
  Device,
  DeviceType,
  Manufacturer,
  OperatingSystem,
  OperatingSystemRelease,
  OperatingSystemVersion,
};

export default ozmoApi;
export { ozmoApiRequest, queryClient };
