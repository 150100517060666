import { FC } from 'react';
import { Button, CircularProgress, ButtonProps } from '@mui/material';

type Props = ButtonProps & {
  isLoading?: boolean;
};

const LoadingButton: FC<Props> = ({ isLoading, children, ...buttonProps }) => {
  return (
    <Button
      {...buttonProps}
      startIcon={
        isLoading ? (
          <CircularProgress color="inherit" size="1rem" />
        ) : (
          buttonProps.startIcon
        )
      }
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
