import { FC, useMemo, ChangeEvent } from 'react';
import { Box, Skeleton, styled } from '@mui/material';
import LoadingIndicator from 'components/loading-indicator';
import { SelectedAttributes } from 'components/attribute-selector';
import { AnswerExistsError } from 'scenes/add-content/error';
import AnswerExistsErrorDetail from 'scenes/add-content/answer-exists-error-detail';
import AutocompleteMultiselect from 'components/autocomplete-multiselect';
import AddAttributeSelector from 'components/add-attribute-selector';

import { ModalInstructions } from './instructions';
import { SpaceSelect } from './space-select';

import type { Attributes } from 'scenes/add-content/modal-body';

type Props = {
  contentEntry: ContentEntryModel | null;
  isLoading: boolean;
  isDuplicating: boolean;
  handleAttributesChange: (attributes: SelectedAttributes) => void;
  handleSpaceIdChange: (spaceId: number | string) => void;
  handleCategoriesChange: (
    _: ChangeEvent<{}>,
    categories: AutocompleteOption[]
  ) => void;
  selectedSpaceId: number;
  selectedCategories?: AutocompleteOption[];
  answerExistsError?: AnswerExistsError;
  initialAttributes?: Attributes;
  categories?: CategoryModel[];
};

type AutocompleteOption = { id: number; name: string };

const StyledAddAttributeSelector = styled(AddAttributeSelector)`
  display: flex;
  flex-direction: column-reverse;
  /* style the attribute results panel */
  #attribute-selector-results-panel {
    max-height: 250px;
    bottom: 0.5rem;
  }
`;

const StyledDiv = styled(Box)`
  min-height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const StyledLoadingContainer = styled(Box)`
  position: absolute;
  inset: -1rem;
  z-index: 10;
  background-color: var(--color-neutral-one);
  display: flex;
  align-content: center;
`;

const StyledAutocompleteMultiselect = styled(AutocompleteMultiselect)`
  margin-bottom: 3rem;
` as typeof AutocompleteMultiselect;

export const DuplicateContentDialogContent: FC<Props> = ({
  contentEntry,
  isLoading,
  isDuplicating,
  handleAttributesChange,
  handleSpaceIdChange,
  handleCategoriesChange,
  selectedSpaceId,
  selectedCategories,
  answerExistsError,
  initialAttributes,
  categories,
}) => {
  const contentEntryReady = contentEntry && !isLoading && selectedSpaceId;
  const options = useMemo(
    () => categories?.map(({ id, name }) => ({ id, name })) ?? [],
    [categories]
  );

  if (!contentEntryReady) {
    return (
      <StyledDiv>
        <Skeleton
          data-testid="duplicate-content-modal-text-skeleton"
          variant="text"
          width={500}
        />
        <Skeleton
          data-testid="duplicate-content-modal-category-skeleton"
          variant="text"
          width={500}
        />
        <Skeleton
          data-testid="duplicate-content-modal-rect-skeleton"
          variant="rectangular"
          width={300}
          height={200}
        />
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <ModalInstructions contentEntry={contentEntry as ContentEntryModel} />
      <SpaceSelect spaceId={selectedSpaceId} onChange={handleSpaceIdChange} />
      {categories && (
        <StyledAutocompleteMultiselect
          label="Category"
          onChange={handleCategoriesChange}
          initialSelectedOptions={selectedCategories}
          options={options}
        />
      )}
      <StyledAddAttributeSelector
        startingAttributes={initialAttributes}
        prohibitedAttributes={[
          'ContentTitle',
          'Space',
          'ContentType',
          'Language',
          'Topic',
        ]}
        onSelectedAttrChange={handleAttributesChange}
      />

      {answerExistsError && (
        <Box paddingTop={4}>
          <AnswerExistsErrorDetail error={answerExistsError} />
        </Box>
      )}

      {/* we can't unmount the attributes selector or it overwrites back to initial attributes, so 
          instead, overlay the duplicating loading indicator */}
      {isDuplicating && (
        <StyledLoadingContainer>
          <LoadingIndicator message="Duplicating content…" />
        </StyledLoadingContainer>
      )}
    </StyledDiv>
  );
};
