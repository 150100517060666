import { FunctionComponent, memo } from 'react';
import { Typography, styled, css } from '@mui/material';
import { truncateFilename } from 'services/utils/file-utils';

import CanvasCompositeImage from '../canvas-composite-image';

const StyledImageWrapper = styled('div')<{ isVertical: boolean }>`
  max-height: 350px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  margin: 8px;

  ${({ isVertical }) =>
    isVertical
      ? css`
          height: 100%;
        `
      : css`
          width: 100%;
        `}
`;

type Props = {
  files: File[];
  deviceShell?: DeviceShellModel;
  onCompositeComplete: (link: HTMLAnchorElement) => void;
  onRemoveFile: (index: number) => void;
};

const ImagePreviewArea: FunctionComponent<Props> = ({
  files,
  deviceShell,
  onCompositeComplete,
  onRemoveFile,
}) => {
  if (!deviceShell) return null;

  const { REACT_APP_S3_ENVIRONMENT } = process.env;
  const {
    overlayImagePath: overlaySource,
    imagePath: shellSource,
    name,
    x,
    y,
    height,
    width,
  } = deviceShell ?? {};

  const screenCoordinates = {
    x,
    y,
    height,
    width,
  };
  const isVertical = height > width;

  const removeFileWithIndex = (i: number) => () => onRemoveFile(i);

  return (
    <>
      {files.map((file, index) => (
        <StyledImageWrapper
          key={`${index}-${file.name}`}
          isVertical={isVertical}
        >
          <CanvasCompositeImage
            file={file}
            overlaySource={
              overlaySource && `${REACT_APP_S3_ENVIRONMENT}${overlaySource}`
            }
            shellSource={`${REACT_APP_S3_ENVIRONMENT}${shellSource}`}
            shellName={name}
            screenCoordinates={screenCoordinates as any}
            onCompositeComplete={onCompositeComplete}
            onRemoveFile={removeFileWithIndex(index)}
          />
          <Typography title={file.name}>
            {truncateFilename(file.name, 25)}
          </Typography>
        </StyledImageWrapper>
      ))}
    </>
  );
};

export default memo(ImagePreviewArea);
