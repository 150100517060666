import { scale, breakpoint } from '@ozmo/typography';

export const defaultTheme = {
  colors: {
    primary: '#006dc7',
    primaryLight: '#007ee6',
    primaryLighter: '#1f9eff',
    primaryDark: '#005499',
    primaryDarker: '#004880',

    secondary: '#00dbc5',

    brand: '#0076D1',
    brandLight: '#0592ff',

    brandDark: '#00599e',

    neutral1: '#ffffff',
    neutral2: '#fafafa',
    neutral3: '#f2f2f2',
    neutral4: '#dcdcdd',
    neutral5: '#b2b2b3',
    neutral6: '#8b8b8d',
    neutral7: '#595f64',
    neutral8: '#1a242d',

    messagingError: '#d10000',
    messagingSuccess: '#15bc79',

    indicatorInner: '#fff200',
    indicatorOuter: 'rgba(249, 201, 0, 0.25)',

    gray3: '#f2f2f2',
    gray7: '#595f64',
  },
  flexboxgrid: {
    gutterWidth: 0,
    outerMargin: 0,
    breakpoints: {
      xs: breakpoint.phablet,
      sm: breakpoint.tablet,
      md: breakpoint.desktop,
    },
  },
  fonts: {
    primary: "'Roboto', arial, sans-serif",
  },
  space: {},
  fontSizes: {
    base: [14, 16, 20],
    ratio: 1.2,
    scale,
  },
};
