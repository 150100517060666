import {
  useState,
  useReducer,
  createContext,
  useEffect,
  useContext,
} from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import {
  Button,
  Snackbar,
  CircularProgress,
  Alert,
  AlertColor,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * **WARNING! You almost certainly do not want to use this function!**
 *
 * In almost every case, instead of importing and using this function you
 * should instead import the custom hook useAppToast() and use that instead.
 *
 * This exported version should only be used in very carefully considered
 * circumstances in consultation with experienced Studio engineers!
 */
// eslint-disable-next-line import/no-mutable-exports
let appToastDispatch = (action: AppToastAction) =>
  console.error(`
    You tried to display a toast using appToastDispatch before the AppToastProvider was
    rendered.  This probably means you are using appToastDispatch incorrectly.

    More than likely what you *really* want to use is useAppToast() instead. Example:

    import { useAppToast } from 'contexts/app-toast-context';
    const SomeComponent = (props) => {
      const dispatchToast = useAppToast();

      return <Button onClick={() => dispatchToast({ level: "info", message: "I clicked it!" })} />
    }
`);

type AppToastAction = {
  level: AlertColor;
  message: string | JSX.Element;
  buttonText?: string;
  onButtonClick?: (...args: any[]) => void | null | Promise<any>;
  linkText?: string;
  linkPath?: string;
};

type State = {
  level?: AlertColor;
  message: string | JSX.Element;
  buttonText?: string;
  onButtonClick?: (...args: any[]) => void | null | Promise<any>;
  linkText?: string;
  linkPath?: string;
};

const StyledLink = styled(Link)`
  margin-left: 4px;
  color: #043b1d;
`;

const StyledLoadingContainer = styled('div')`
  margin-left: 1rem;
  width: 1.5rem;
`;

const AppToastContext = createContext<(arg: AppToastAction) => void>(() => {});

/**
 * useAppToast() - A convenience hook to access the AppToastContext
 *
 * Call dispatchToast(toast\<Object\>) with a dictionary defining the toast you want to pop to the user
 *
 * toast dictionary takes these params:
 * * level \<string\>: One of ['success','info','warning','error'],
 * * message \<string\>: The toast message to display
 * * buttonText \<string\>: The text to display on an option action button
 * * onButtonClick \<function\>: The optional button callback to execute if the button is pressed
 *
 * @returns {function} dispatchToast()
 */
const useAppToast = () => {
  return useContext(AppToastContext);
};

const initialState = {
  message: '',
};

const alertReducer = (state: State, action: AppToastAction): State => {
  const {
    level,
    message,
    buttonText,
    onButtonClick,
    linkText,
    linkPath,
  } = action;
  switch (level) {
    case 'error':
      return {
        level,
        message,
        buttonText,
        onButtonClick,
      };
    case 'warning':
      return {
        level,
        message,
        buttonText,
        onButtonClick,
      };
    case 'info':
      return {
        level,
        message,
        buttonText,
        onButtonClick,
      };
    case 'success':
      return {
        level,
        message,
        buttonText,
        onButtonClick,
        linkText,
        linkPath,
      };
    default:
      return {
        buttonText: '',
        message: '',
      };
  }
};

const AppToastProvider: FCWithChildren = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [sideEffectInProgress, setSideEffectInProgress] = useState(false);
  const [toast, dispatchToast] = useReducer(alertReducer, initialState);
  appToastDispatch = dispatchToast;
  const {
    level,
    message,
    buttonText,
    onButtonClick,
    linkText,
    linkPath,
  } = toast;

  useEffect(() => {
    if (toast.level !== undefined) {
      setShowToast(true);
    }
  }, [toast]);

  const handleClose = () => setShowToast(false);

  const handleButtonClick = async () => {
    if (onButtonClick) {
      setSideEffectInProgress(true);
      await onButtonClick();
      setSideEffectInProgress(false);
    }
  };

  return (
    <AppToastContext.Provider value={dispatchToast}>
      {children}
      {toast.level && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showToast}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={level}
            style={{ alignItems: 'center' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {message}
              {onButtonClick && sideEffectInProgress ? (
                <StyledLoadingContainer>
                  <CircularProgress size="1rem" />
                </StyledLoadingContainer>
              ) : (
                <Button
                  size="small"
                  onClick={handleButtonClick}
                  style={{ marginLeft: 16 }}
                >
                  {buttonText}
                </Button>
              )}
              {linkText && linkPath && (
                <StyledLink to={linkPath} onClick={() => setShowToast(false)}>
                  <b>{linkText}</b>
                </StyledLink>
              )}
            </div>
          </Alert>
        </Snackbar>
      )}
    </AppToastContext.Provider>
  );
};

export type { AlertColor, AppToastAction };
export { AppToastContext, AppToastProvider, useAppToast };
// This is intentionally NOT re-exported out of index.ts so people
// don't accidentally import and use this instead of useAppToast
export { appToastDispatch };
