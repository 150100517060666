import styled from 'styled-components';
import icons from './icons';

import Svg from './svg';

const Path = styled.path({
  fill: 'currentColor',
});

export const Icons = ({ icon, ...props }) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <Path d={icons[icon]} />
    </Svg>
  );
};
