import { useEffect, useRef, FunctionComponent } from 'react';
import PropTypes from 'prop-types';

type Props = {
  locale: string;
};

declare global {
  interface Window {
    appTier: 'prod' | 'dev';
    ozmoAnalyticsEnvironment?: 'local';
  }
}

const PreviewContent: FunctionComponent<Props> = ({ locale }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const refClosure = ref.current;
      // Undocumented flag to use the dev content tier on the engineering prod API
      window.appTier = 'dev';
      // undocumented flag to not send analytics for preview
      window.ozmoAnalyticsEnvironment = 'local';

      const tag = document.createElement('script');
      tag.async = false;
      tag.dataset.target = 'e2e-portal-root';
      tag.dataset.apiKey = process.env.REACT_APP_E2E_PORTAL_KEY;
      // This is the route/path we want to use locally to access
      tag.dataset.basename = `preview/${locale}`;
      tag.dataset.locale = locale;
      tag.dataset.testid = 'preview-content:loader-script';
      tag.src =
        process.env.REACT_APP_E2E_PORTAL_SCRIPT_URL ??
        'https://content.sds.modeaondemand.com/portal/loader.min.js';
      ref.current.appendChild(tag);

      return () => {
        refClosure.removeChild(tag);
      };
    }
  }, [locale]);

  return (
    <div ref={ref}>
      <div id="e2e-portal-root" data-testid="preview-content:e2e-portal-root" />
    </div>
  );
};

PreviewContent.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default PreviewContent;
