import { HotspotIndicator } from '@ozmo/indicator';
import { func, number, bool, string } from 'services/utils/prop-types';

import DraggableBase, { BaseProps } from '../draggable-base';
import SelectedIndicator from '../selected-indicator';

export type Props = BaseProps;

const DraggablePointOfInterest: React.FC<Props> = (props) => (
  <DraggableBase
    {...props}
    indicatorType={'HOTSPOT'}
    selectedComponent={SelectedIndicator}
    unselectedComponent={HotspotIndicator}
  />
);

DraggablePointOfInterest.propTypes = {
  index: number.isRequired,
  initialX: number.isRequired,
  initialY: number.isRequired,
  parentHeight: number.isRequired,
  parentWidth: number.isRequired,
  initialSelected: bool,
  isPaused: bool,
  boundingParentSelector: string,
  onSave: func.isRequired,
  onDelete: func.isRequired,
};

export default DraggablePointOfInterest;
