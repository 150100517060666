import { Box, Typography } from '@mui/material';
import { ReactComponent as EmptyFolderIcon } from 'icons/streamline-ux-empty-folder.svg';

const EmptyTablePlaceholder = () => (
  <Box textAlign="center">
    <EmptyFolderIcon />
    <Typography fontWeight="bold">
      {'We’re sorry, we couldn’t find any results. '}
    </Typography>
    <Typography>{'Please try searching again.'}</Typography>
  </Box>
);

export default EmptyTablePlaceholder;
