import styled from 'styled-components';

const Svg = styled.svg(
  {
    shapeRendering: 'inherit',
    transform: 'translate3d(0,0,0)',
  },
  ({ inline }) =>
    inline
      ? {
          display: 'inline-block',
        }
      : {
          display: 'block',
        }
);

Svg.displayName = 'Svg';

export { Svg as default };
