import { memo, useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import { Icons } from 'components/icon/icon';
import styled from 'styled-components';

const StyledHelpIcon = styled(HelpIcon)`
  color: #006dc7;
`;

const { REACT_APP_VERSION } = process.env;

const HelpMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        data-testid="help-menu:button"
        onClick={handleClick}
        size="large"
      >
        <StyledHelpIcon />
      </IconButton>
      <Menu
        data-testid="help-menu:menu"
        id="help-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="help-menu:platform-guide"
          onClick={() => {
            window.open(
              'https://modeacorp.atlassian.net/wiki/spaces/AT/pages/1279295489/Tutorial+Authoring+Platform+User+Guide',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <ListItemIcon>
            <Icons icon={'openInNew'} height={'24px'} width={'24px'} />
          </ListItemIcon>
          <ListItemText>{'Platform User Guide'}</ListItemText>
        </MenuItem>
        <MenuItem
          data-testid="help-menu:report-issue"
          onClick={() => {
            window.open(
              'https://modeacorp.atlassian.net/secure/CreateIssue.jspa?issuetype=1&pid=12527',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <ListItemIcon>
            <Icons icon={'openInNew'} height={'24px'} width={'24px'} />
          </ListItemIcon>
          <ListItemText>{'Report issue'}</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText>{`${REACT_APP_VERSION}`}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(HelpMenu);
