import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: inline;
  height: 100%;
  vertical-align: middle;
`;

const StyledInfoIcon = styled(InfoIcon)`
  color: var(--color-neutral-six);
`;

const InfoTooltip = ({ height = 'small', children }) => (
  <StyledContainer>
    <Tooltip
      arrow
      fontSize={height}
      data-testid="info-tooltip:tooltip"
      placement="bottom-start"
      title={children}
      leaveDelay={100}
    >
      <StyledInfoIcon />
    </Tooltip>
  </StyledContainer>
);

export default InfoTooltip;
