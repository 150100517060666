import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useAppToast } from 'contexts/app-toast-context';
import usePreviousPage from 'services/utils/use-previous-page';
import { useContentEntry } from 'services/hooks/api-hooks';
import { useActionModal } from 'components/modals';
import { SelectedAttributes } from 'components/attribute-selector';
import { AnswerExistsError } from 'scenes/add-content/error';
import {
  isAnswerExistsResponse,
  createAnswerExistsError,
  isAnswerExistsError,
} from 'scenes/add-content/utils';

import {
  contentEntryToAttributes,
  getDisplayContentType,
  isListEqual,
} from './utils';
import { DuplicateContentDialogContent } from './dialog-content';

import type { Attributes } from 'scenes/add-content/modal-body';

export const KEY_MAP: Record<keyof Attributes, string> = {
  Device: 'deviceIds',
  DeviceType: 'deviceTypeIds',
  Manufacturer: 'manufacturerIds',
  OperatingSystem: 'operatingSystemIds',
  OperatingSystemRelease: 'operatingSystemReleaseIds',
  OperatingSystemVersion: 'operatingSystemVersionIds',
};

export const useContentEntryLanguages = (contentEntryId: number) => {
  const { contentEntry, isLoading } = useContentEntry(contentEntryId);

  const languages = isLoading
    ? []
    : contentEntry.localizedContentEntries.map(
        (lce) => `${lce.languageDisplayName} (${lce.languageShortCode})`
      );

  return {
    languages,
    isLoading,
  };
};

const useForm = (contentEntryId: number | null) => {
  const { contentEntry, isLoading } = useContentEntry(contentEntryId);
  const [selectedSpaceId, setSelectedSpaceId] = useState(contentEntry?.spaceId);
  const [attributes, setAttributes] = useState<Partial<SelectedAttributes>>({});
  const [answerExistsError, setAnswerExistsError] = useState<
    AnswerExistsError | undefined
  >();

  const handleChangeAttributes = useCallback(
    (attributes: SelectedAttributes) => {
      setAttributes(attributes as any);
      setAnswerExistsError(undefined);
    },
    [setAttributes]
  );

  const initialAttributes = useMemo(
    () => contentEntryToAttributes(contentEntry),
    [contentEntry]
  );

  const canDuplicate = useMemo(() => {
    if (contentEntry?.spaceId !== selectedSpaceId) return true;
    if (!initialAttributes || !attributes) return false;

    const allAttributesEqual = Object.entries(KEY_MAP).every(
      ([initialKey, attributeKey]) => {
        return isListEqual(
          initialAttributes[initialKey as keyof Attributes],
          attributes[attributeKey]
        );
      }
    );

    return !allAttributesEqual;
  }, [initialAttributes, attributes, contentEntry?.spaceId, selectedSpaceId]);

  useEffect(() => {
    if (contentEntry?.spaceId) {
      setSelectedSpaceId(contentEntry.spaceId);
      setAttributes(
        contentEntryToAttributes(contentEntry) as SelectedAttributes
      );
    }
  }, [isLoading, contentEntry]);

  const handleSpaceChange = useCallback(
    (spaceId: number | string) => {
      const parsedId =
        typeof spaceId === 'number' ? spaceId : parseInt(spaceId, 10);
      setSelectedSpaceId(parsedId);
      setAnswerExistsError(undefined);
    },
    [setSelectedSpaceId]
  );

  return {
    contentEntry,
    isLoading,
    selectedSpaceId,
    handleSpaceChange,
    initialAttributes,
    attributes,
    handleChangeAttributes,
    canDuplicate,
    answerExistsError,
    setAnswerExistsError,
  };
};

export const useDuplicateContentEntry = (contentEntryId: number | null) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const { pathname } = useLocation();
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const { contentEntry } = useContentEntry(contentEntryId);
  const [isDuplicating, setisDuplicating] = useState(false);

  if (!contentEntry)
    return {
      onDuplicate: null,
      isDuplicating,
    };

  const onDuplicate = async (
    spaceId: number,
    attributes: SelectedAttributes = {}
  ): Promise<ContentEntryModel | AnswerExistsError | undefined> => {
    try {
      setisDuplicating(true);
      // we're only duplicating one content entry at a time, so get the first from the reponse
      const [duplicateContentEntry] = await api.ContentEntry.copyAsync(
        contentEntry.id,
        {
          ...attributes,
          spaceIds: [spaceId],
        }
      );

      // make a specific call to the get the duplicate so space and lce will be embedded
      const embeddedDuplicateContentEntry = await api.ContentEntry.getAsync({
        id: duplicateContentEntry.id,
      });

      if (embeddedDuplicateContentEntry) {
        const displayContentType = getDisplayContentType(
          embeddedDuplicateContentEntry.contentTypeId
        );

        const urlPrefix =
          displayContentType === 'Collection' ? 'collection' : 'edit';

        dispatchToast({
          level: 'success',
          message: `Success! ${embeddedDuplicateContentEntry.title} was duplicated into`,
          linkText: embeddedDuplicateContentEntry.space?.name,
          linkPath: generateUrlWithPreviousPage(
            `/${urlPrefix}/${embeddedDuplicateContentEntry.id}`,
            pathname,
            displayContentType
          ),
        });
        const queryKey = api.ContentEntry.getQueryKey(undefined, undefined, []);
        api.queryClient.invalidateQueries(queryKey);
        return embeddedDuplicateContentEntry;
      }

      // if above doesn't return, throw error
      throw new Error('embeddedDuplicateContentEntry entry is undefined');
    } catch (error) {
      if (isAnswerExistsResponse(error)) {
        const sourceLanguageIds = contentEntry.localizedContentEntries.map(
          (lce) => lce.languageId
        );
        return createAnswerExistsError(error, sourceLanguageIds);
      }
      dispatchToast({
        level: 'error',
        message: `${contentEntry.title} could not be duplicated. Please try again.`,
      });
      return undefined;
    } finally {
      setisDuplicating(false);
    }
  };

  return {
    onDuplicate,
    isDuplicating,
  };
};

export const useDuplicateContentModal = (
  contentEntryId: number | null,
  onDuplicateComplete?: (contentEntryId: number) => void,
  onClose?: VoidFunction
) => {
  const {
    contentEntry,
    isLoading,
    selectedSpaceId,
    handleSpaceChange,
    initialAttributes,
    attributes,
    handleChangeAttributes,
    canDuplicate,
    answerExistsError,
    setAnswerExistsError,
  } = useForm(contentEntryId);

  const { onDuplicate, isDuplicating } = useDuplicateContentEntry(
    contentEntryId
  );

  const handleClose = useCallback(() => {
    setAnswerExistsError(undefined);
    onClose?.();
  }, [onClose, setAnswerExistsError]);

  const handleDuplicateConfirm = useCallback(async () => {
    // if confirming after a duplicate error response, use the duplicate
    if (answerExistsError) {
      onDuplicateComplete?.(answerExistsError.existingAnswerId);
      onClose?.();
      setAnswerExistsError(undefined);
      return;
    }

    if (onDuplicate) {
      const duplicatedContent = await onDuplicate(selectedSpaceId, attributes);

      if (isAnswerExistsError(duplicatedContent)) {
        setAnswerExistsError(duplicatedContent);
        throw new Error();
      }

      if (duplicatedContent) {
        onDuplicateComplete?.(duplicatedContent.id);
      } else {
        onClose?.();
      }
    }
  }, [
    answerExistsError,
    onDuplicate,
    selectedSpaceId,
    attributes,
    onDuplicateComplete,
    onClose,
    setAnswerExistsError,
  ]);

  const { modal, openModal } = useActionModal({
    closeOnReject: false,
    onConfirm: handleDuplicateConfirm,
    onRefuse: handleClose,
    disableConfirmButtonOnClick: !answerExistsError,
    modalContent: (
      <DuplicateContentDialogContent
        contentEntry={contentEntry}
        isLoading={isLoading}
        isDuplicating={isDuplicating}
        handleSpaceIdChange={handleSpaceChange}
        selectedSpaceId={selectedSpaceId}
        answerExistsError={answerExistsError}
        initialAttributes={initialAttributes}
        handleAttributesChange={handleChangeAttributes}
        handleCategoriesChange={() => {}}
      />
    ),
    modalProps: {
      title: 'You are about to duplicate an answer',
      confirmButtonText: answerExistsError
        ? 'Use existing answer'
        : 'Duplicate answer',
      maxWidth: 'md',
      disableConfirmButton: !canDuplicate,
    },
  });

  return {
    modal,
    openModal,
  };
};
