import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import PrivateRoute from 'services/private-route';
import Contents from 'scenes/contents';
import Home from 'scenes/home';
import Collection from 'scenes/collection';
import NuevoCollection from 'scenes/nuevo-collection';
import HealthCheck from 'scenes/health-check';
import PreviewContentEntry from 'scenes/preview-content-entry';
import ShellCompiler from 'scenes/shell-compiler';
import { useFlag } from 'services/flags';
import { HomeTabs } from 'types/enums';
import { useTrackPageViews } from 'services/analytics';
import UniversalContentEditor from 'scenes/universal-content-editor';

const AuthoringRouting = () => {
  const haveFlagsLoaded = useFlag('haveFlagsLoaded', false);
  const [searchParams] = useSearchParams();
  useTrackPageViews();

  if (!haveFlagsLoaded) return null;

  return (
    <Routes>
      <Route
        path="/edit/:contentEntryId/:localeOrLanguage"
        element={
          <PrivateRoute>
            <UniversalContentEditor />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit/:contentEntryId"
        element={
          <PrivateRoute>
            <UniversalContentEditor />
          </PrivateRoute>
        }
      />
      <Route
        path="/collection/:id/:language/:categoryId"
        element={
          <PrivateRoute>
            <NuevoCollection />
          </PrivateRoute>
        }
      />
      <Route
        path="/collection/:id/:language"
        element={
          <PrivateRoute>
            <NuevoCollection />
          </PrivateRoute>
        }
      />
      <Route
        path="/collection/:id"
        element={
          <PrivateRoute>
            <NuevoCollection />
          </PrivateRoute>
        }
      />
      <Route
        path="/legacy-collection/:id/:language/:selectedCategoryIndex"
        element={
          <PrivateRoute>
            <Collection />
          </PrivateRoute>
        }
      />
      <Route
        path="/legacy-collection/:id/:language"
        element={
          <PrivateRoute>
            <Collection />
          </PrivateRoute>
        }
      />
      <Route
        path="/legacy-collection/:id"
        element={
          <PrivateRoute>
            <Collection />
          </PrivateRoute>
        }
      />

      <Route
        path="/contents"
        element={
          <PrivateRoute>
            <Contents />
          </PrivateRoute>
        }
      />
      <Route
        path="/preview/:language/tutorial/entry/:contentEntryId"
        element={
          <PrivateRoute>
            <PreviewContentEntry />
          </PrivateRoute>
        }
      />
      <Route
        path="/shell-compiler"
        element={
          <PrivateRoute>
            <ShellCompiler />
          </PrivateRoute>
        }
      />
      {/* redirect to collections */}
      <Route
        path="/"
        element={
          <Navigate to={`/collections?${searchParams}`} replace={true} />
        }
      />
      <Route
        path="/answers"
        element={
          <PrivateRoute>
            <Home defaultTab={HomeTabs.ANSWERS} />
          </PrivateRoute>
        }
      />
      <Route
        path="/collections"
        element={
          <PrivateRoute>
            <Home defaultTab={HomeTabs.COLLECTIONS} />
          </PrivateRoute>
        }
      />

      <Route path="/healthcheck" element={<HealthCheck />} />
      <Route path="*" element={<div>{'no matching route found'}</div>} />
    </Routes>
  );
};

export default AuthoringRouting;
