import { ChangeEvent, FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import { ReactComponent as VolcanoPolaroid } from 'icons/volcano-polaroid.svg';
import styled from 'styled-components';

const StyledLabel = styled.label`
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-primary-base);
  & > input[type='file'] {
    display: none;
  }
`;

const StyledNoImageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 250px;
  height: 100%;
  border: 2px dashed #8d8d8d;
  text-align: center;
  border-radius: 6px;
  & div,
  p {
    margin: 12px 16px;
  }
`;

type Props = {
  path: string;
  errorMessage?: string;
  onFileSelect: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ReactFunctionalComponent: FunctionComponent<Props> = ({
  onFileSelect,
  errorMessage,
  path,
}) => {
  const inputId = `fileSelect-${path}`;
  return (
    <StyledNoImageContainer>
      <VolcanoPolaroid />
      <Typography variant="body1" color="neutral-six" component="div">
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        {'Drag or '}
        <StyledLabel htmlFor={inputId}>
          <input
            id={inputId}
            accept="image/*"
            type="file"
            onChange={onFileSelect}
          />
          {'select an image'}
        </StyledLabel>
        <br />
        {' from your computer.'}
      </Typography>
    </StyledNoImageContainer>
  );
};

export default ReactFunctionalComponent;
