import { ChangeEvent, FC, useMemo, useCallback } from 'react';
import {
  MenuItem,
  TextField,
  Grid,
  Box,
  Collapse,
  Skeleton,
  Typography,
  styled,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import ContentTypeWithIcon from 'components/content-type-with-icon';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { Select } from 'components/select';
import { TopicSelector } from 'components/topic-selector';
import { SelectedAttributes } from 'components/attribute-selector';
import { usePermissions } from 'components/permission-required';
import { ContentTypes } from 'types/enums';
import { useFlag } from 'services/flags';
import AddAttributeSelector from 'components/add-attribute-selector';

import { Types as ContentTypeIds } from './constants';
import LanguageSelect from './languages-select';
import AnswerExistsErrorDetail from './answer-exists-error-detail';
import { AnswerExistsError } from './error';

export type Attributes = {
  Device: number[];
  DeviceType: number[];
  Manufacturer: number[];
  OperatingSystem: number[];
  OperatingSystemRelease: number[];
  OperatingSystemVersion: number[];
};

export type Props = {
  contentTypeId?: number;
  title?: string;
  spaceId?: number;
  answerExistsError?: AnswerExistsError;
  languageIds?: number[];
  topicId?: number;
  description?: string;
  attributes?: Attributes;
};

type AdditionalProps = {
  contentTypeBlocklist?: string[];
  contentTypeAllowlist?: string[];
  setFormItem: (itemName: keyof Props, value: any) => void;
};

const StyledAddAttributeSelector = styled(AddAttributeSelector)`
  display: flex;
  flex-direction: column-reverse;
  /* style the attribute results panel */
  #attribute-selector-results-panel {
    max-height: 250px;
    bottom: 0.5rem;
  }
`;

// default values to empty strings via recommendation for unfilled MUI selects
const AddContentModalBody: FC<Props & AdditionalProps> = ({
  contentTypeId = '',
  title = '',
  description = '',
  spaceId = '',
  topicId,
  answerExistsError,
  languageIds = [],
  attributes,
  contentTypeAllowlist,
  contentTypeBlocklist = [],
  setFormItem,
}) => {
  const api = useOzmoApiService();

  const useCollections2 = useFlag('useCollections2', false);

  const {
    all: contentTypes,
    isLoading: contentTypesLoading,
  } = api.ContentType.getAll();

  const { all: spaces, isLoading: spacesLoading } = api.Space.getAll();
  const { checkPermission } = usePermissions();

  const hasPermissionForContentType = useCallback(
    (contentType: string) => {
      switch (contentType) {
        // collection
        case ContentTypes.COLLECTION:
          return checkPermission('edit_collection');
        default:
          return true;
      }
    },
    [checkPermission]
  );

  const contentTypesOrdered = useMemo(() => {
    if (!contentTypes) {
      return [];
    }

    // filter out content types that aren't production ready
    const productionReadyContentTypes = contentTypes.filter(
      ({ isProductionReady }) => isProductionReady
    );

    const collectionType = productionReadyContentTypes.find(
      (ct) => ct.name === ContentTypes.COLLECTION
    );
    const nonCollectionTypes = productionReadyContentTypes.filter(
      (ct) => ct.name !== ContentTypes.COLLECTION
    );

    // filter out options based on the block/allowlists
    return [collectionType, ...nonCollectionTypes]
      .filter((ct) => !!ct)
      .filter(
        (ct) =>
          !contentTypeAllowlist || contentTypeAllowlist.includes(ct!!.name)
      )
      .filter((ct) => !contentTypeBlocklist.includes(ct!!.name))
      .filter((ct) =>
        hasPermissionForContentType(ct!.name)
      ) as ContentTypeModel[];
  }, [
    contentTypes,
    contentTypeAllowlist,
    contentTypeBlocklist,
    hasPermissionForContentType,
  ]);

  const setFormItemOnChange = useCallback(
    (fieldName: keyof Props) => (e: ChangeEvent<Element>) => {
      setFormItem(fieldName, (e.target as HTMLInputElement).value);
    },
    [setFormItem]
  );

  const setFormItemAttributes = useCallback(
    (selectedAttributes: SelectedAttributes) => {
      setFormItem('attributes', selectedAttributes);
    },
    [setFormItem]
  );

  if (contentTypesLoading) {
    return <Skeleton variant="rectangular" width="100%" height="4rem" />;
  }

  if (contentTypesOrdered.length === 0) {
    return (
      <Box display="flex" component="span" gap={1}>
        <InfoOutlined fontSize="small" color="error" />
        <Typography color="error">
          {
            "You don't have permission to create content. Please contact your team lead."
          }
        </Typography>
      </Box>
    );
  }

  const DEFAULT_MAX_WIDTH = 500;

  return (
    <Grid>
      <Box padding={2} maxWidth={DEFAULT_MAX_WIDTH}>
        <Select
          value={contentTypeId}
          label="Content type"
          onChange={setFormItemOnChange('contentTypeId') as any}
        >
          {!contentTypesLoading &&
            contentTypesOrdered.map(({ id, name, displayName }) => (
              <MenuItem
                key={id}
                value={id}
                disabled={!hasPermissionForContentType(name)}
              >
                <ContentTypeWithIcon
                  contentType={name}
                  label={displayName}
                  styleOverrides={{
                    color: 'var(--color-neutral-seven)',
                    fontWeight: 'normal',
                  }}
                />
              </MenuItem>
            ))}
        </Select>
      </Box>

      <Collapse in={!!contentTypeId}>
        <Box maxWidth={DEFAULT_MAX_WIDTH}>
          <Box padding={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              value={title}
              onChange={setFormItemOnChange('title')}
            />
          </Box>

          <Collapse in={contentTypeId !== ContentTypeIds.COLLECTION}>
            <Box padding={2}>
              <TopicSelector
                label="Topic"
                currentTopicId={topicId}
                onChange={(_, newTopic) => {
                  if (!newTopic) return;
                  setFormItemOnChange('topicId')({
                    target: { value: newTopic.id },
                  } as any);
                }}
              />
            </Box>
          </Collapse>

          <Collapse
            in={contentTypeId === ContentTypeIds.COLLECTION && !useCollections2}
          >
            <Box padding={2}>
              <TextField
                fullWidth
                multiline
                minRows={2}
                label="Description"
                value={description}
                onChange={setFormItemOnChange('description')}
              />
            </Box>
          </Collapse>

          <Collapse
            in={contentTypeId !== ContentTypeIds.COLLECTION || !useCollections2}
          >
            <Box padding={2}>
              <Select
                value={spaceId}
                label="Space"
                onChange={setFormItemOnChange('spaceId') as any}
              >
                {!spacesLoading &&
                  spaces
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((space) => (
                      <MenuItem key={space.id} value={space.id}>
                        {space.name}
                      </MenuItem>
                    ))}
              </Select>
            </Box>
          </Collapse>

          <Collapse
            in={contentTypeId !== ContentTypeIds.COLLECTION || !useCollections2}
          >
            <Box padding={2}>
              <LanguageSelect
                languageIds={languageIds}
                setLanguages={setFormItemOnChange('languageIds')}
              />
            </Box>
          </Collapse>
        </Box>
        <Box padding={2} maxWidth={1000}>
          <StyledAddAttributeSelector
            startingAttributes={attributes}
            prohibitedAttributes={[
              'ContentTitle',
              'Space',
              'ContentType',
              'Language',
              'Topic',
            ]}
            onSelectedAttrChange={setFormItemAttributes}
          />
        </Box>
      </Collapse>

      {answerExistsError && (
        <AnswerExistsErrorDetail error={answerExistsError} />
      )}
    </Grid>
  );
};

export default AddContentModalBody;
