import { FunctionComponent, useCallback, memo } from 'react';
import { Typography, IconButton, styled, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import usePreviousPage from 'services/utils/use-previous-page';
import StatusIndicator from 'components/status-indicator';
import { HiddenUnder } from 'components/hidden';
import LinkBox from 'components/link-box';
import { ContentTypes } from 'types/enums';

import Reference, {
  StyledChildrenWithSpaceBox,
  StyledHiddenIfCategoriesOpen,
  Props as ReferenceProps,
} from './base-reference';
import {
  MissingTitle,
  Title,
  Topic,
  LocalizedReferenceActions,
} from './components';

type Props = LocalizedCollectionReference &
  ReferenceProps & {
    categoryId: number;
    collectionId: number;
    onInfoClick: (contentEntryId: number) => void;
  };

// The parent (a few up) will have a class called "side-panel-selected-content-entry-123" when
// the side panel is open, and this component will have "content-entry-123" always, so when
// they line up/match apply these styles
const StyledReference = styled(Reference)<{ $referenceId: number }>`
  ${({ $referenceId }) =>
    `.side-panel-selected-content-entry-${$referenceId} .content-entry-${$referenceId}`}& {
    background-color: var(--color-primary-background);
  } ;
`;

const LocalizedReference: FunctionComponent<Props> = ({
  asCard,
  collectionId,
  categoryId,
  selected,
  onInfoClick,
  onSelectChange,
  ...reference
}) => {
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const {
    contentType,
    space,
    status,
    title,
    topicSlug,
    updatedAt,
    deletedAt,
    complete,
  } = reference;
  const editUrl = generateUrlWithPreviousPage(
    `/edit/${reference.id}/${reference.languageShortCode}`,
    undefined,
    'Collection'
  );

  const handleInfoClick = useCallback(() => {
    onInfoClick(reference.id);
  }, [reference.id, onInfoClick]);

  return (
    <StyledReference
      $referenceId={reference.id}
      className={`content-entry-${reference.id}`}
      asCard={asCard}
      actions={[
        <LocalizedReferenceActions
          key={0}
          editUrl={editUrl}
          categoryId={categoryId}
          collectionId={collectionId}
          {...reference}
        />,
      ]}
      contentType={deletedAt ? ContentTypes.DELETED : contentType}
      selected={selected}
      onSelectChange={onSelectChange}
      flagIncomplete={!complete}
    >
      <Box display="block" minWidth="5rem" flex="10">
        <LinkBox url={editUrl}>
          <Box display="flex" flexDirection="row">
            {title ? (
              <Title>{title}</Title>
            ) : (
              <MissingTitle>
                {'Please update the title for this localized answer'}
              </MissingTitle>
            )}
            <Typography
              color="var(--color-neutral-six)"
              fontSize="0.875rem"
              display="inline"
              margin="0 2rem 0 3px"
              alignSelf="self-end"
            >{`(#${reference.id})`}</Typography>
          </Box>
          <Topic slug={topicSlug} />
        </LinkBox>
      </Box>
      <StyledHiddenIfCategoriesOpen flex={6} breakpoint="sm">
        <StyledChildrenWithSpaceBox
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            color="var(--color-neutral-seven)"
            noWrap={true}
            textAlign="left"
          >
            {space}
          </Typography>
          <HiddenUnder breakpoint="md">
            <Typography color="var(--color-neutral-seven)" textAlign="right">
              {new Date(updatedAt).toLocaleDateString()}
            </Typography>
          </HiddenUnder>
        </StyledChildrenWithSpaceBox>
      </StyledHiddenIfCategoriesOpen>
      <div>
        <StatusIndicator status={status} size={12} />
      </div>
      <div>
        <IconButton
          aria-label="Info"
          onClick={handleInfoClick}
          data-testid={`localized-reference:info-button-${reference.id}`}
        >
          <InfoIcon />
        </IconButton>
      </div>
    </StyledReference>
  );
};

export default memo(LocalizedReference);
