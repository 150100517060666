import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import InfoTooltip from 'components/info-tooltip';
import { string, number } from 'prop-types';

const StyledAttributeTabContainer = styled.div`
  margin-top: 21px;
`;

type Props = {
  title: string;
  contentTypeId?: number;
};

const AttributeHeader: FunctionComponent<Props> = ({
  title,
  contentTypeId,
}) => {
  return (
    <StyledAttributeTabContainer>
      <Typography variant="h5">
        {'Attributes for '}
        <b>{title ?? 'Unknown title'}</b>
        &nbsp;
        <InfoTooltip>
          <b>{'Attributes'}</b>
          <div>
            {'Attributes are like tags, but they have more structure. ' +
              'Add as many attributes as you need to describe your content. '}
          </div>
        </InfoTooltip>
      </Typography>
      <Typography>
        {'Select attributes to help describe what your content ' +
          'is related to and help improve its findability later. '}
      </Typography>
      {contentTypeId && contentTypeId === 3 && (
        <Typography>
          <i>
            {'Note: Attributes will only be updated for the collection. ' +
              'Topic attributes within this collection will not be updated.'}
          </i>
        </Typography>
      )}
      <br />
      <b>{'Search to filter results'}</b>
    </StyledAttributeTabContainer>
  );
};

AttributeHeader.propTypes = {
  title: string.isRequired,
  contentTypeId: number,
};

export default AttributeHeader;
