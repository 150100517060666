import { FC } from 'react';
import SelectLanguage from 'components/select-language';

import StickyHeader from './sticky-header';
import Title from './title';
import { useCurrentCollection } from './hooks';

import { BaseHeaderProps } from '.';

type Props = BaseHeaderProps;

const CollectionHeader: FC<Props> = ({
  actionButtons,
  onSelectLanguage,
  currentTab,
  setCurrentTab,
  tabLabels,
}) => {
  const {
    isLoading,
    isError,
    error,
    collection,
    handleTitleUpdate,
    languageId,
  } = useCurrentCollection();

  const {
    manufacturers = [],
    devices = [],
    deviceTypes = [],
    operatingSystems = [],
    operatingSystemReleases = [],
    operatingSystemVersions = [],
  } = collection || {};

  const title = (
    <Title
      isCollection
      title={collection?.name || '(loading)'}
      contentId={collection?.id || undefined}
      onTitleUpdate={handleTitleUpdate}
    />
  );

  const languageSelector = (
    <SelectLanguage
      languageId={languageId!}
      onSelect={(lang) => onSelectLanguage(lang?.shortCode!)}
    />
  );

  return (
    <StickyHeader
      {...{
        isLoading,
        isError,
        error,
        title,
        actionButtons,
        manufacturers,
        devices,
        deviceTypes,
        operatingSystems,
        operatingSystemReleases,
        operatingSystemVersions,
        languageSelector,
        currentTab,
        setCurrentTab,
        tabLabels,
      }}
    />
  );
};

export default CollectionHeader;
