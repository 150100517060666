import { useMemo, FunctionComponent, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  margin-bottom: 12px !important;
  font-weight: 500 !important;
`;

const StyledContainer = styled.div`
  margin-bottom: 24px;
`;

type SelectableDeviceModel = DeviceModel & {
  isSelected?: boolean;
};

type Props = {
  manufacturer: string;
  devices: SelectableDeviceModel[];
  onSelect: (
    attributeName: string,
    attribute: DeviceModel,
    action: 'add' | 'remove'
  ) => void;
};

type Accumulator = {
  [key: string]: SelectableDeviceModel[];
};

const DeviceSelector: FunctionComponent<Props> = ({
  manufacturer,
  devices,
  onSelect,
}) => {
  const devicesByManufacturer = useMemo(
    () =>
      devices.reduce(
        (acc, device) => ({
          ...acc,
          [device.manufacturer]: [...(acc[device.manufacturer] ?? []), device],
        }),
        {} as Accumulator
      ),
    [devices]
  );
  const devicesCount = manufacturer
    ? (devicesByManufacturer[manufacturer] ?? []).length
    : devices.length;

  const handleSelect = (
    event: ChangeEvent<HTMLInputElement>,
    device: DeviceModel
  ) => {
    const action = event?.target.checked ? 'add' : 'remove';
    onSelect('device', device, action);
  };

  if (devicesCount > 100) {
    return (
      <Typography variant="caption">
        {`Too many results, Add a text filter ${
          manufacturer ? '' : 'or select a manufacturer '
        } to reduce results`}
      </Typography>
    );
  }

  return (
    <StyledContainer>
      <StyledTypography variant="h6">{'Devices'}</StyledTypography>
      {devicesCount === 0 && <Typography>{'No results'}</Typography>}
      {Object.entries(devicesByManufacturer)
        .filter(([m]) => manufacturer === '' || m === manufacturer)
        .sort()
        .map(([manufacturer, devices]) => (
          <div
            key={manufacturer}
            data-testid={`device-selector:${manufacturer}-group`}
          >
            <Typography variant="body1">
              <b>{manufacturer}</b>
            </Typography>
            {devices.map((device) => (
              <div key={device.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={device.isSelected}
                      onChange={(e) => handleSelect(e, device)}
                    />
                  }
                  label={formatAttributeName(device.trackingName)}
                />
              </div>
            ))}
          </div>
        ))}
    </StyledContainer>
  );
};

DeviceSelector.propTypes = {
  manufacturer: PropTypes.string.isRequired,
};

export default DeviceSelector;
