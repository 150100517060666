import { useState, useMemo, useCallback, useRef, ChangeEvent } from 'react';
import {
  UrlParamOptions,
  UseQueryOptionsWithPrefetch,
  generateQueryKey,
} from 'services/ozmo-api/use-query-cache';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { usePermissions } from 'components/permission-required';

import {
  filterOperatingSystemVersions,
  filterDevices,
} from './attribute-editor-utils';

type Params = {
  contentEntryId?: number;
  onChange?: (
    attributeName: string,
    attribute: Attribute,
    action: 'add' | 'remove'
  ) => void;
  selectedDevices?: DeviceModel[];
  selectedDeviceTypes?: DeviceTypeModel[];
  selectedManufacturers?: ManufacturerModel[];
  selectedOperatingSystems?: OperatingSystemModel[];
  selectedOperatingSystemReleases?: OperatingSystemReleaseModel[];
  selectedOperatingSystemVersions?: OperatingSystemVersionModel[];
};

export const useAttributEditor = ({
  contentEntryId,
  onChange,
  selectedDevices,
  selectedDeviceTypes,
  selectedManufacturers,
  selectedOperatingSystemReleases,
  selectedOperatingSystemVersions,
  selectedOperatingSystems,
}: Params) => {
  const { checkPermission } = usePermissions();

  const hasPermission = checkPermission(
    'PATCH:/v1/authoring/content_entries/*/',
    'API'
  );
  const [filterText, setFilterText] = useState<string>('');
  const [filter, setFilter] = useState({ primary: '', secondary: '' });
  const filterBarRef = useRef<HTMLDivElement>(null);
  const api = useOzmoApiService();
  // Options to apply to all API service requests
  // In particular, set the staleTime to Infinity, so the data is never refetched
  // for the sake of the API.  These are config models that almost never change so
  // it is fine if the only way to refresh them in the cache is to reload the page
  const cacheOptions: UseQueryOptionsWithPrefetch = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  };
  const queryOptions: UrlParamOptions = { perPage: 1000 };

  // Set the table, get all the attributes into the cache
  const allDevices = api.Device.getAll(undefined, cacheOptions, queryOptions);
  const allDeviceTypes = api.DeviceType.getAll(
    undefined,
    cacheOptions,
    queryOptions
  );
  const allManufacturers = api.Manufacturer.getAll(
    undefined,
    cacheOptions,
    queryOptions
  );
  const allOperatingSystemVersions = api.OperatingSystemVersion.getAll(
    undefined,
    cacheOptions,
    queryOptions
  );

  const contentEntry = api.ContentEntry.get({ id: contentEntryId });

  const { primary, secondary } = filter;
  const {
    title = 'Unknown title',
    contentTypeId,
    devices = selectedDevices ?? [],
    deviceTypes = selectedDeviceTypes ?? [],
    manufacturers = selectedManufacturers ?? [],
    operatingSystems = selectedOperatingSystems ?? [],
    operatingSystemReleases = selectedOperatingSystemReleases ?? [],
    operatingSystemVersions = selectedOperatingSystemVersions ?? [],
  } = contentEntry.data ?? {};

  const filteredOperatingSystemVersions = useMemo(() => {
    const currentOperatingSystemVersionIds = (
      operatingSystemVersions ?? []
    ).map((v) => v.id);
    return filterOperatingSystemVersions(
      allOperatingSystemVersions.all,
      filterText
    ).map((v) => ({
      ...v,
      isSelected: currentOperatingSystemVersionIds.includes(v.id),
    }));
  }, [filterText, allOperatingSystemVersions.all, operatingSystemVersions]);

  const filteredDevices = useMemo(() => {
    const currentDeviceIds = (devices ?? []).map((d) => d.id);
    return filterDevices(allDevices.all, filterText).map((d) => ({
      ...d,
      isSelected: currentDeviceIds.includes(d.id),
    }));
  }, [filterText, allDevices.all, devices]);

  const filteredDeviceTypes = useMemo(() => {
    const currentDeviceTypeIds = (deviceTypes ?? []).map((d) => d.id);
    return allDeviceTypes.all
      .map((d) => ({
        ...d,
        isSelected: currentDeviceTypeIds.includes(d.id),
      }))
      .filter((d) => d.name.toLowerCase().includes(filterText.toLowerCase()));
  }, [filterText, allDeviceTypes.all, deviceTypes]);

  const filteredManufacturers = useMemo(() => {
    const currentManufacturerIds = (manufacturers ?? []).map((m) => m.id);
    return allManufacturers.all
      .map((m) => ({
        ...m,
        isSelected: currentManufacturerIds.includes(m.id),
      }))
      .filter((m) => m.name.toLowerCase().includes(filterText.toLowerCase()));
  }, [filterText, allManufacturers.all, manufacturers]);

  const handleClickFilter = (primary: string, secondary: string = '') => {
    setFilter({ primary, secondary });
  };

  const handleClearAllFilters = () => {
    setFilter({ primary: '', secondary: '' });
    setFilterText('');
  };

  const handleSetFilterText = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setFilterText(e.target.value);

  const handleSelect = useCallback(
    (attributeName: string, attribute: Attribute, action: 'add' | 'remove') => {
      if (onChange) {
        return onChange(attributeName, attribute, action);
      }
      if (action === 'add') {
        // invalidate the query to force a refetch. This is to get any higher order attributes that may have been added
        if (contentEntry) {
          api.queryClient.invalidateQueries(
            generateQueryKey(contentEntry.id, 'authoring/content_entries')
          );
        }
        switch (attributeName) {
          case 'device':
            contentEntry.update({
              deviceIds: [
                ...(contentEntry.data?.devices ?? []).map((a) => a.id),
                attribute.id,
              ],
            });
            contentEntry.updateCache({
              devices: [
                ...(contentEntry.data.devices ?? []),
                attribute as DeviceModel,
              ],
            });
            break;
          case 'deviceType':
            contentEntry.update({
              deviceTypeIds: [
                ...(contentEntry.data.deviceTypes ?? []).map((a) => a.id),
                attribute.id,
              ],
            });
            contentEntry.updateCache({
              deviceTypes: [
                ...(contentEntry.data.deviceTypes ?? []),
                attribute as DeviceTypeModel,
              ],
            });
            break;
          case 'manufacturer':
            contentEntry.update({
              manufacturerIds: [
                ...(contentEntry.data.manufacturers ?? []).map((a) => a.id),
                attribute.id,
              ],
            });
            contentEntry.updateCache({
              manufacturers: [
                ...(contentEntry.data.manufacturers ?? []),
                attribute as ManufacturerModel,
              ],
            });
            break;
          case 'operatingSystem':
            contentEntry.update({
              operatingSystemIds: [
                ...(contentEntry.data.operatingSystems ?? []).map((a) => a.id),
                attribute.id,
              ],
            });
            contentEntry.updateCache({
              operatingSystems: [
                ...(contentEntry.data.operatingSystems ?? []),
                attribute as OperatingSystemModel,
              ],
            });
            break;
          case 'operatingSystemVersion':
            contentEntry.update({
              operatingSystemVersionIds: [
                ...(contentEntry.data.operatingSystemVersions ?? []).map(
                  (a) => a.id
                ),
                attribute.id,
              ],
            });
            contentEntry.updateCache({
              operatingSystemVersions: [
                ...(contentEntry.data.operatingSystemVersions ?? []),
                attribute as OperatingSystemVersionModel,
              ],
            });
            break;
          case 'operatingSystemRelease':
            contentEntry.update({
              operatingSystemReleaseIds: [
                ...(contentEntry.data.operatingSystemReleases ?? []).map(
                  (a) => a.id
                ),
                attribute.id,
              ],
            });
            contentEntry.updateCache({
              operatingSystemReleases: [
                ...(contentEntry.data.operatingSystemReleases ?? []),
                attribute as OperatingSystemReleaseModel,
              ],
            });
            break;
          default:
            console.error(
              `Unable to update unknown attribute type: ${attributeName}`
            );
        }
      } else {
        switch (attributeName) {
          case 'device':
            contentEntry.update({
              deviceIds: (contentEntry.data.devices ?? [])
                .map((a) => a.id)
                .filter((id) => id !== attribute.id),
            });
            contentEntry.updateCache({
              devices: (contentEntry.data.devices ?? []).filter(
                (a) => a.id !== attribute.id
              ),
            });
            break;
          case 'deviceType':
            contentEntry.update({
              deviceTypeIds: (contentEntry.data.deviceTypes ?? [])
                .map((a) => a.id)
                .filter((id) => id !== attribute.id),
            });
            contentEntry.updateCache({
              deviceTypes: (contentEntry.data.deviceTypes ?? []).filter(
                (a) => a.id !== attribute.id
              ),
            });
            break;
          case 'manufacturer':
            contentEntry.update({
              manufacturerIds: (contentEntry.data.manufacturers ?? [])
                .map((a) => a.id)
                .filter((id) => id !== attribute.id),
            });
            contentEntry.updateCache({
              manufacturers: (contentEntry.data.manufacturers ?? []).filter(
                (a) => a.id !== attribute.id
              ),
            });
            break;
          case 'operatingSystem':
            contentEntry.update({
              operatingSystemIds: (contentEntry.data.operatingSystems ?? [])
                .map((a) => a.id)
                .filter((id) => id !== attribute.id),
            });
            contentEntry.updateCache({
              operatingSystems: (
                contentEntry.data.operatingSystems ?? []
              ).filter((a) => a.id !== attribute.id),
            });
            break;
          case 'operatingSystemVersion':
            contentEntry.update({
              operatingSystemVersionIds: (
                contentEntry.data.operatingSystemVersions ?? []
              )
                .map((a) => a.id)
                .filter((id) => id !== attribute.id),
            });
            contentEntry.updateCache({
              operatingSystemVersions: (
                contentEntry.data.operatingSystemVersions ?? []
              ).filter((a) => a.id !== attribute.id),
            });
            break;
          case 'operatingSystemRelease':
            contentEntry.update({
              operatingSystemReleaseIds: (
                contentEntry.data.operatingSystemReleases ?? []
              )
                .map((a) => a.id)
                .filter((id) => id !== attribute.id),
            });
            contentEntry.updateCache({
              operatingSystemReleases: (
                contentEntry.data.operatingSystemReleases ?? []
              ).filter((a) => a.id !== attribute.id),
            });
            break;
          default:
            console.error(
              `Unable to update unknown attribute type ${attributeName}`
            );
        }
      }
    },
    [api.queryClient, contentEntry, onChange]
  );

  return {
    hasPermission,
    filterBarRef,
    primary,
    secondary,
    filter,
    filterText,
    filteredDevices,
    filteredDeviceTypes,
    filteredManufacturers,
    filteredOperatingSystemVersions,
    title,
    contentTypeId,
    devices,
    deviceTypes,
    manufacturers,
    operatingSystems,
    operatingSystemReleases,
    operatingSystemVersions,
    handleClickFilter,
    handleClearAllFilters,
    handleSelect,
    handleSetFilterText,
  };
};
