import styled from 'styled-components';
import { PinchInIndicator, PinchOutIndicator } from '@ozmo/indicator';
import { func, number, bool, string } from 'services/utils/prop-types';

import SelectedIndicator from '../selected-indicator';
import DraggableBase, { BaseProps } from '../draggable-base';

const StyledDraggableWrapper = styled.div<{ isSelected: boolean }>`
  height: 0px;
  width: 0px;
  cursor: pointer;
  &.react-draggable-dragging {
    cursor: grabbing;
  }
  // Immediate child div is the @ozmo/indicator wrapper when not selected
  // Need to give it room to animate so set a height/width on it when not selected
  & > div {
    height: ${({ isSelected }) => (isSelected ? 0 : 400)}px;
    width: ${({ isSelected }) => (isSelected ? 0 : 400)}px;
  }
`;

export type Props = BaseProps & {
  direction: 'in' | 'out';
};

const DraggablePinchIndicator: React.FC<Props> = (props) => (
  <DraggableBase
    {...props}
    indicatorType={'pinch'}
    selectedComponent={SelectedIndicator}
    unselectedComponent={
      props.direction === 'in' ? PinchInIndicator : PinchOutIndicator
    }
    wrapper={StyledDraggableWrapper}
  />
);

DraggablePinchIndicator.propTypes = {
  index: number.isRequired,
  initialX: number.isRequired,
  initialY: number.isRequired,
  parentHeight: number.isRequired,
  parentWidth: number.isRequired,
  initialSelected: bool,
  isPaused: bool,
  boundingParentSelector: string,
  onSave: func.isRequired,
  onDelete: func.isRequired,
};

export default DraggablePinchIndicator;
