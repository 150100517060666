import { Dispatch, SetStateAction, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import usePreviousPage from 'services/utils/use-previous-page';
import { isPermissionDeniedError } from 'services/utils/type-guards/is-permission-denied-error';
import { useFlag } from 'services/flags';

import { Props as FormData, Attributes } from './modal-body';
import { Types } from './constants';
import { AnswerExistsError } from './error';
import { isAnswerExistsResponse, createAnswerExistsError } from './utils';

type WithoutNullableKeys<Type> = {
  [Key in keyof Type]-?: WithoutNullableKeys<NonNullable<Type[Key]>>;
};

export const getLocalizedFinalStepText = (languageId: number) => {
  switch (languageId) {
    // en and default
    case 1:
    default:
      return "You've completed the steps!";
    // es
    case 2:
      return '¡Has completado los pasos!';
    // fr
    case 3:
      return 'Vous avez terminé les étapes!';
    // ja
    case 4:
      return 'ステップを完了しました';
  }
};

export const useContentEntryUrl = (sourceName: string) => {
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const useCollections2 = useFlag('useCollections2', false);
  const { pathname } = useLocation();

  const createUrl = useCallback(
    (newContentEntry: ContentEntryModel) => {
      const pathEdit =
        newContentEntry.contentTypeId === Types.COLLECTION ||
        newContentEntry.contentTypeId === undefined
          ? `collection${useCollections2 ? '' : '2'}`
          : 'edit';

      return generateUrlWithPreviousPage(
        `/${pathEdit}/${newContentEntry.id}`,
        pathname,
        sourceName
      );
    },
    [generateUrlWithPreviousPage, pathname, sourceName, useCollections2]
  );

  return createUrl;
};

export const useContentEntryValidity = (
  formData: FormData,
  useCollections2: Boolean
) => {
  const topicValid =
    formData.contentTypeId === Types.COLLECTION ? true : formData.topicId;

  const hasAttributes =
    !!formData.attributes &&
    (Object.keys(formData.attributes) as Array<keyof Attributes>).some(
      // @ts-ignore it thinks formData.attributes is possible undefined again, despite the check above
      (attributeKey) => formData.attributes[attributeKey]?.length > 0
    );

  // For checking legacy collections fields (language and spaceID)
  // Can remove this when the useCollections2 feature flag is removed
  const hasValidLanguage = (formData.languageIds ?? []).length >= 1;
  const hasValidLegacyCollectionFields = formData.spaceId && hasValidLanguage;

  return (
    formData.contentTypeId &&
    (hasValidLegacyCollectionFields || useCollections2) && // remove line when useCollections2 flag is removed
    formData.title &&
    hasAttributes &&
    topicValid
  );
};

export const useCreateLocalizedContentEntries = () => {
  const api = useOzmoApiService();
  const createLocalizedContentEntries = async (
    newContentEntry: ContentEntryModel,
    languageIds: number[]
  ) => {
    try {
      return Promise.allSettled(
        languageIds.map((languageId) => {
          const body = {
            contentEntryId: newContentEntry.id,
            languageId,
            properties: {
              title: newContentEntry.title,
            },
          };
          if (newContentEntry.contentTypeId === 1) {
            const interactiveTutorialBody = {
              ...body,
              properties: {
                ...body.properties,
                steps: [
                  { command: '', notes: [] },
                  {
                    command: getLocalizedFinalStepText(languageId),
                    notes: [],
                  },
                ],
              },
            };
            return api.LocalizedContentEntry.createAsync(
              interactiveTutorialBody,
              {
                contentEntryId: newContentEntry.id,
              }
            );
          }
          if (newContentEntry.contentTypeId === 2) {
            const poiBody = {
              ...body,
              properties: {
                ...body.properties,
                pointsOfInterest: [],
              },
            };
            return api.LocalizedContentEntry.createAsync(poiBody, {
              contentEntryId: newContentEntry.id,
            });
          }
          return api.LocalizedContentEntry.createAsync(body, {
            contentEntryId: newContentEntry.id,
          });
        })
      );
    } catch (error) {
      return error;
    }
  };

  return createLocalizedContentEntries;
};

export const useSaveNewContent = (
  sourceName: string,
  defaultFormState: FormData,
  setIsSaving: Dispatch<SetStateAction<boolean>>,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  setFormState: Dispatch<SetStateAction<FormData>>,
  onSave?: (id: number) => void
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const createLocalizedContentEntries = useCreateLocalizedContentEntries();
  const createUrl = useContentEntryUrl(sourceName);
  const useCollections2 = useFlag('useCollections2', false);

  const handleSave = useCallback(
    async (
      dataOrContentEntryId: number | WithoutNullableKeys<FormData>
    ): Promise<AnswerExistsError | boolean> => {
      // in the event of a matching already-existing content entry ID being passed, simply
      // invoke the onSave callback to allow the caller to do with the entry as it needs
      if (typeof dataOrContentEntryId === 'number') {
        onSave?.(dataOrContentEntryId);
        setIsOpen(false);
        setFormState(defaultFormState);

        dispatchToast({
          level: 'success',
          message: 'Answer successfully added to this collection!',
          linkText: 'Go to answer',
          linkPath: createUrl(({
            id: dataOrContentEntryId,
            contentTypeId: Types.TUTORIAL,
          } as unknown) as ContentEntryModel),
        });

        return true;
      }

      const {
        contentTypeId,
        spaceId,
        title,
        topicId,
        languageIds,
        description,
        attributes,
      } = dataOrContentEntryId;

      try {
        setIsSaving(true);

        const contentEntryData = {
          contentTypeId,
          spaceId,
          title,
          topicId: contentTypeId === Types.COLLECTION ? undefined : topicId, // remove topic from collections
          ...attributes,
        };

        let newContentEntry: BaseModel;
        if (contentTypeId !== Types.COLLECTION) {
          newContentEntry = await api.ContentEntry.createAsync(
            contentEntryData
          );

          await createLocalizedContentEntries(
            newContentEntry as ContentEntryModel,
            languageIds
          );
        } else if (useCollections2) {
          newContentEntry = await api.Collection.createAsync({
            name: title,
            ...attributes,
          });
        } else {
          const localizedContentEntryProperties = {
            title,
            description,
            items: [],
          };
          newContentEntry = await api.ContentEntry.createWithLocalizedContentEntriesAsync(
            contentEntryData,
            localizedContentEntryProperties,
            languageIds
          );
        }

        onSave?.(newContentEntry.id);
        setIsOpen(false);
        setFormState(defaultFormState);

        dispatchToast({
          level: 'success',
          message: `"${title}" was successfully created.`,
          linkText:
            contentTypeId === Types.COLLECTION
              ? 'Go to collection'
              : 'Go to answer',
          linkPath: createUrl(newContentEntry as ContentEntryModel),
        });

        return true;
      } catch (ex) {
        // if this error is the result of a clashing answer, determine language overlap
        // and return the appropriate error
        if (isAnswerExistsResponse(ex)) {
          return createAnswerExistsError(ex, languageIds);
        }

        // A toast will be popped from use-query-cache.tsx in this case
        if (isPermissionDeniedError(ex)) {
          return false;
        }

        // if not an existing answer and just a general error, pop toast
        dispatchToast({
          level: 'error',
          message: 'An error occured. Please try creating your answer again.',
        });

        return false;
      } finally {
        setIsSaving(false);
      }
    },
    [
      setIsSaving,
      onSave,
      setIsOpen,
      setFormState,
      defaultFormState,
      dispatchToast,
      createUrl,
      api.ContentEntry,
      createLocalizedContentEntries,
      api.Collection,
      useCollections2,
    ]
  );

  return handleSave;
};

export const usePreviewContent = (contentEntryId: number) => {
  const api = useOzmoApiService();
  const { all: lces } = api.LocalizedContentEntry.getAll({
    contentEntryId,
  });
  // get the english locale from the lce's if one exists, otherwise get
  // the locale of the first lce
  const locale: string | undefined =
    lces.find((lce) => lce.languageShortCode === 'en')?.locale ||
    lces[0]?.locale;

  const previewUrl = locale
    ? `/preview/${locale}/tutorial/entry/${contentEntryId}?previewOnly=true`
    : undefined;

  return {
    previewUrl,
  };
};
