import { FunctionComponent } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
import TrashChip from 'components/trash-chip/trash-chip';

import useSelectLanguageFromContentEntry from './hooks/use-select-language-from-content-entry';

const MenuItemContent = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &.deleted {
    color: var(--color-neutral-six);
    font-style: italic;
  }
`;

type Props = {
  contentEntryId?: number;
  showAddLanguage?: boolean;
  disableAddLanguage?: boolean;
  languageShortCode?: string;
  onSelect: (languageShortCode: string) => void;
  copyContent?: boolean;
};

const SelectLanguageFromContentEntry: FunctionComponent<Props> = ({
  contentEntryId,
  languageShortCode,
  onSelect,
  copyContent,
  disableAddLanguage,
  showAddLanguage = true,
}) => {
  const {
    contentEntry,
    isLoading,
    handleSelect,
    addLocalModal,
  } = useSelectLanguageFromContentEntry(contentEntryId, onSelect, copyContent);

  if (isLoading || !languageShortCode || !contentEntryId) {
    return (
      <Skeleton
        variant="rectangular"
        height={40}
        data-testid="select-language-from-content-entry:skeleton"
      />
    );
  }

  return (
    <FormControl>
      <InputLabel id="select-language-label">{'Language'}</InputLabel>
      <Select
        label="Language"
        labelId="select-language-label"
        value={languageShortCode}
        onChange={handleSelect}
      >
        {contentEntry.localizedContentEntries.map((l) => (
          <MenuItem key={l.id} value={l.languageShortCode}>
            <MenuItemContent className={!!l.deletedAt ? 'deleted' : undefined}>
              <span>{`${l.languageDisplayName} (${l.languageShortCode})`}</span>
              {!!l.deletedAt && <TrashChip iconSize="medium" />}
            </MenuItemContent>
          </MenuItem>
        ))}
        {showAddLanguage && (
          // can't use PermissionRequired here due to how MUI Select components work.
          // in short, the value context is lost since MenuItem would no longer be a direct
          // child of the Select
          <MenuItem value={'add'} disabled={disableAddLanguage}>
            <ListItemIcon>
              <PlusIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                color: 'var(--color-primary-base)',
              }}
              primary="Add languages"
            />
          </MenuItem>
        )}
      </Select>
      {addLocalModal}
    </FormControl>
  );
};

export default SelectLanguageFromContentEntry;
