import { useMemo, FunctionComponent, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import styled from 'styled-components';

import { useOperatingSystemSelectorWarningModal } from './hooks';

const StyledBoldTypography = styled(Typography)`
  font-weight: 500 !important;
  margin-top: 12px !important;
`;

const StyledBoldMarginTypography = styled(StyledBoldTypography as any)`
  margin-bottom: 12px !important;
  margin-top: 0px !important;
`;

const StyledContainer = styled.div`
  margin-bottom: 24px;
`;

type SelectableOperatingSystemVersionModel = OperatingSystemVersionModel & {
  isSelected?: boolean;
};

type Props = {
  operatingSystem: string;
  selectedOperatingSystemIds: number[];
  selectedOperatingSystemReleaseIds: number[];
  operatingSystemVersions: SelectableOperatingSystemVersionModel[];
  onSelect: (
    attributeName: string,
    attribute:
      | OperatingSystemVersionModel
      | OperatingSystemReleaseModel
      | OperatingSystemModel,
    action: 'add' | 'remove'
  ) => void;
};

type Accumulator = {
  [key: string]: {
    id: number;
    name: string;
    releases: {
      [key: string]: {
        id: number;
        name: string;
        versions: SelectableOperatingSystemVersionModel[];
      };
    };
  };
};

const OperatingSystemSelector: FunctionComponent<Props> = ({
  operatingSystem,
  operatingSystemVersions,
  selectedOperatingSystemIds,
  selectedOperatingSystemReleaseIds,
  onSelect,
}) => {
  const { modal, openModal } = useOperatingSystemSelectorWarningModal();

  const versionsByOperatingSystemAndRelease = useMemo(
    () =>
      operatingSystemVersions.reduce(
        (acc, version) => ({
          ...acc,
          [version.operatingSystem]: {
            id: version.operatingSystemId,
            name: version.operatingSystem,
            releases: {
              ...(acc[version.operatingSystem]?.releases ?? []),
              [version.operatingSystemRelease]: {
                id: version.operatingSystemReleaseId,
                name: version.operatingSystemRelease,
                versions: [
                  ...((acc[version.operatingSystem]?.releases ?? {})[
                    version.operatingSystemRelease
                  ]?.versions ?? []),
                  version,
                ],
              },
            },
          },
        }),
        {} as Accumulator
      ),
    [operatingSystemVersions]
  );

  const operatingSystemVersionCount = operatingSystem
    ? (
        Object.values(
          Object.values(
            versionsByOperatingSystemAndRelease[operatingSystem].releases
          ).map((r) => r.versions)
        ).flat() ?? []
      ).length
    : operatingSystemVersions.length;

  const handleSelectOperatingSystem = (
    event: ChangeEvent<HTMLInputElement>,
    id: number,
    name: string
  ) => {
    const action = event?.target.checked ? 'add' : 'remove';
    onSelect(
      'operatingSystem',
      {
        id,
        name,
        createdAt: Date.now().toString(),
        updatedAt: Date.now().toString(),
        shouldDisplayReleaseName: true,
        displayOrder: 0,
      } as OperatingSystemModel,
      action
    );
  };

  const handleSelectOperatingSystemRelease = (
    event: ChangeEvent<HTMLInputElement>,
    id: number,
    name: string,
    operatingSystemName: string
  ) => {
    const action = event?.target.checked ? 'add' : 'remove';
    onSelect(
      'operatingSystemRelease',
      {
        id,
        name,
        operatingSystemName,
        createdAt: Date.now().toString(),
        updatedAt: Date.now().toString(),
      } as OperatingSystemReleaseModel,
      action
    );
  };

  const handleSelectOperatingSystemVersion = (
    event: ChangeEvent<HTMLInputElement>,
    version: OperatingSystemVersionModel
  ) => {
    const action = event?.target.checked ? 'add' : 'remove';
    action === 'add' && openModal();
    onSelect('operatingSystemVersion', version, action);
  };

  if (operatingSystemVersionCount > 100) {
    return (
      <Typography variant="caption">
        {`Too many results, Add a text filter ${
          operatingSystem ? '' : 'or select an operating system '
        } to reduce results`}
      </Typography>
    );
  }

  return (
    <StyledContainer>
      <StyledBoldMarginTypography variant="h6">
        {'OS Release'}
      </StyledBoldMarginTypography>
      {operatingSystemVersionCount === 0 && (
        <Typography>{'No results'}</Typography>
      )}
      {Object.entries(versionsByOperatingSystemAndRelease)
        .filter(([os]) => operatingSystem === '' || os === operatingSystem)
        .sort()
        .map(([operatingSystem, { id, releases }]) => (
          <div key={operatingSystem}>
            <StyledBoldTypography variant="body1">
              {operatingSystem}
            </StyledBoldTypography>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedOperatingSystemIds.includes(id)}
                  onChange={(e) =>
                    handleSelectOperatingSystem(e, id, operatingSystem)
                  }
                />
              }
              label={`All ${operatingSystem} releases (and future releases)`}
            />
            {Object.entries(releases).map(([release, { id, versions }]) => (
              <div key={release}>
                <StyledBoldTypography>{`${operatingSystem} ${release} release`}</StyledBoldTypography>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedOperatingSystemReleaseIds.includes(id)}
                      onChange={(e) =>
                        handleSelectOperatingSystemRelease(
                          e,
                          id,
                          release,
                          operatingSystem
                        )
                      }
                    />
                  }
                  label={`All ${operatingSystem} ${release} versions (and future versions released)`}
                />
                {versions.map((version) => (
                  <div key={version.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={version.isSelected}
                          onChange={(e) =>
                            handleSelectOperatingSystemVersion(e, version)
                          }
                        />
                      }
                      label={`${operatingSystem} ${version.name}`}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      {modal}
    </StyledContainer>
  );
};

OperatingSystemSelector.propTypes = {
  operatingSystem: PropTypes.string.isRequired,
};

export default OperatingSystemSelector;
