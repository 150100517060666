import { FunctionComponent } from 'react';
import { Search } from '@mui/icons-material';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import LoadingIndicator from 'components/loading-indicator';

import QuickAddSearchResult from './quick-add-search-result';
import { useQuickAddSearch } from './use-quick-add-search';
import QuickAddNoResults from './quick-add-no-results';

const StyledTextField = styled(TextField)`
  /* Disable padding underneath <input> element */
  & input {
    padding: 0px;
  }
  /* Make <input placeholder="" /> text smaller */
  & input::placeholder {
    font-size: 0.875rem;
  }
`;

const StyledMenuItem = styled(Box)`
  /* Disable blue background to selected list item */
  &.Mui-focusVisible {
    background-color: inherit;
  }
`;

type Props = {
  categoryName: string;
  existingReferences: number[];
  languageId: number;
  onSelectResult: (id: number) => Promise<any>;
  onRemoveResult: (id: number) => Promise<any>;
};

const QuickAddSearch: FunctionComponent<Props> = ({
  categoryName,
  existingReferences,
  languageId,
  onSelectResult,
  onRemoveResult,
}) => {
  const {
    inputRef,
    isError,
    isFetching,
    result,
    handleKeyDown,
    handleQuickAddClick,
  } = useQuickAddSearch(languageId);

  const showResults = isFetching || isError || result;
  const isExistingReference = existingReferences.some(
    (id) => id === result?.id
  );

  return (
    <>
      <StyledMenuItem
        // Disable KeyboardEvent propagation so MUI doesn't try to navigate the menu
        // on keypress, which breaks typing certain characters in textfields
        onKeyDown={(e) => e.stopPropagation()}
        component={MenuItem}
        width="100%"
        minWidth="30rem"
        paddingTop={0}
        paddingBottom={0}
        onClick={handleQuickAddClick}
      >
        <ListItemIcon>
          <Search fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <StyledTextField
            fullWidth
            autoFocus
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            InputProps={{ disableUnderline: true }}
            placeholder={`Quick add by answer ID # to ${categoryName}`}
            variant="standard"
          />
        </ListItemText>
      </StyledMenuItem>
      {showResults && (
        <>
          <Divider />
          <Box
            paddingBottom={1}
            paddingLeft={2}
            paddingRight={2}
            paddingTop={1}
          >
            {isFetching && !result && (
              <LoadingIndicator message="Searching..." />
            )}
            {isError && <QuickAddNoResults />}
            {result && (
              <QuickAddSearchResult
                categoryName={categoryName}
                contentType={result.contentType}
                id={result.id}
                title={result.title}
                topicSlug={result.topicSlug}
                isExistingReference={isExistingReference}
                onSelectResult={onSelectResult}
                onRemoveResult={onRemoveResult}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default QuickAddSearch;
