import { Link, useMatch } from 'react-router-dom';

const ContentList = () => {
  const { url } = useMatch();

  return (
    <div>
      <p>{'Here$apos;s a content list'}</p>
      <ul>
        <li>
          <Link to={`${url}/'blah'`}>{'[Edit]'}</Link>
        </li>
      </ul>
    </div>
  );
};

export default ContentList;
